import React, { Component } from "react"
import { Helmet } from "react-helmet"

import { ContactForm, Layout } from "../../../components/"

import {
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"

import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/contours/logocontours.png"
import Header from "../../../images/reference/contours/header-marketing.png"
import Marek from "../../../images/reference/contours/reaction-marketing.png"
import Top from "../../../images/reference/contours/top.jpg"

class Contours extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(128,0,128,0.8),rgba(128,0,0,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Individuální školení Facebook reklamy pro Contours | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//contours.cz/"
          heading="Individuální školení Facebook reklamy pro Contours"
          odstavec="jaro 2015"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Firma Contours, s. r. o. je součástí mezinárodní franchisové
            sítě posiloven pro ženy a provozuje po celé České republice
            celkem 27 poboček. Nabízí unikátní kruhový trénink s důrazem na
            redukci tělesné hmotnosti a vyznačuje se jedinečným osobním
            přístupem ke svým klientkám."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p className="text-center">
                Připravit školení na míru včetně struktury pro efektivní využití
                možností Facebook reklamy. Zaškolit klienta jak v teoretické,
                tak praktické rovině.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>identifikace cílů a cílových skupin</li>
                <li>konzultace při technické přípravě reklamního účtu</li>
                <li>
                  nastavení systému práce s osloveným publikem za využití
                  různých rovin Facebook reklamy včetně remarketingu
                </li>
                <li>celodenní školení Facebook reklamy</li>
                <li>
                  praktické zaškolení do správy připravené struktury kampaní
                </li>
              </ul>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Průběh spolupráce</TitleH2>
              <p>
                Na počátku měl klient zájem čistě o školení Facebook reklamy. Po
                několika telefonických konzultacích jsme se společně rozhodli,
                že mnohem efektivnější bude připravit rámec, který bude schopen
                po zaškolení dlouhodobě využívat pro své komerční účely. Poté,
                co bylo nastaveno vše nezbytné, jsme uspořádali školení za
                účasti naší interní lektorky Veroniky Jozifové a tří zástupců z
                klientské strany. Zadavatel byl s jeho výsledkem spokojen a
                především je nyní schopen spravovat své kampaně sám bez
                závislosti na dalším externím článku.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Zhodnocení zadavatele</TitleH2>
              <p>
                Na školení oceňujeme zejména připravenost školitelky. Informace
                a materiály, s nimiž jsme pracovali, byly připraveny přímo na
                míru, díky čemuž bylo školení skutečně praktické. Nejprve jsme
                získali přehled napříč možnostmi facebookové reklamy, poté jsme
                pracovali na konkrétních nastaveních přímo v našem inzerentském
                účtu. Díky tomu jsme znalosti ze školení ihned využili pro naši
                firmu. Školení rozhodně doporučujeme každému, kdo chce mít
                kontrolu nad svými kampaněmi a má zájem o práci s pokročilými
                možnostmi, mezi něž patří například remarketing. A právě
                remarketing byl pro nás tou nejzajímavější záležitostí.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>

          <Medailon
            wide
            image={Marek}
            alt=""
            text="Školení rozhodně doporučujeme každému, kdo chce mít kontrolu nad svými kampaněmi."
            name="Marek Klemens, DiS."
            position="Area Manager"
          />
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Contours
